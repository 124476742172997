
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { getAddressById } from "@/components/x-address-select/api";

const loaded: { [key: number]: any } = {};

@Component({
  name: "x-address-select"
})
export default class XAddressSelect extends Vue {
  @PropSync('value', {
    default: () => [],
    required: true,
  }) propValue!: any[];

  @Prop({ default: false }) clearable!: boolean;

  @Prop({ default: () => '100%' }) width!: string;


  props: any = null!;

  created() {
    this.props = {
      lazy: true,
      expandTrigger: 'hover',
      lazyLoad: (node, resolve) => {
        let id = 0;

        if (!node.root) {
          id = node.data.value;

          if (node.data.leaf) {
            return resolve([]);
          }
        }

        if (loaded[id]) {
          return setTimeout(() => {
            resolve(loaded[id]);
          }, 500);
        }

        getAddressById(id).then(resp => {
          const results = resp.map(item => {
            return {
              label: item.local_name,
              value: item.id,
              leaf: !!item.is_leaf,
            };
          });

          loaded[id] = results;

          resolve(results);
        });
      },
    };
  }
}
